<template>
  <div class="default-slider">
    <div class="d-flex mb-8">
      <div
        class="default-slider__title mr-8 mb-0"
        v-if="title"
        v-html="title"
      ></div>
      <div
        class="default-slider__arrows mt-0 ml-auto d-none d-md-flex"
        v-if="showArrows"
      >
        <div
          class="default-slider__arrow mr-3"
          :style="`--color-hover: ${color}`"
          @click="$refs.defaultSlider.prev()"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.25 4.50012L3.75 12.0001L11.25 19.5001"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="square"
            />
            <path
              d="M5.25 12.0001L20.25 12.0001"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="square"
            />
          </svg>
        </div>
        <div
          class="default-slider__arrow ml-3"
          :style="` --color-hover: ${color}`"
          @click="$refs.defaultSlider.next()"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.75 4.50012L20.25 12.0001L12.75 19.5001"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="square"
            />
            <path
              d="M18.75 12.0001L3.75 12.0001"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="square"
            />
          </svg>
        </div>
      </div>
    </div>

    <VueSlickCarousel
      v-bind="defaultSettings"
      ref="defaultSlider"
      @init="onInitCarousel"
      @beforeChange="beforeChange"
      @afterChange="afterChange"
      @reInit="onInitCarousel"
    >
      <slot name="items"></slot>
    </VueSlickCarousel>
    <div class="default-slider__arrows d-md-none" v-if="showArrows">
      <div
        class="default-slider__arrow mr-3"
        @click="$refs.defaultSlider.prev()"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.25 4.50012L3.75 12.0001L11.25 19.5001"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="square"
          />
          <path
            d="M5.25 12.0001L20.25 12.0001"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="square"
          />
        </svg>
      </div>
      <div
        class="default-slider__arrow ml-3"
        @click="$refs.defaultSlider.next()"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.75 4.50012L20.25 12.0001L12.75 19.5001"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="square"
          />
          <path
            d="M18.75 12.0001L3.75 12.0001"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="square"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
export default {
  name: "defaultSlider",
  props: {
    color: { type: String, default: "#830051" },
    title: String,
    settings: Object
  },
  components: {
    VueSlickCarousel,
  },
  data: () => ({
    showArrows: true,
    autoplayInterval: null,
    loadAnim: false,
    defaultSettings: {
      infinite: true,
      slidesToShow: 2,
      arrows: false,
      dots: false,
      responsive: [
        {
          breakpoint: 1220,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    },
  }),
  computed: {},
  methods: {
    onInitCarousel() {
      this.showArrows =
        this.$refs.defaultSlider?.settings.slidesToShow <
        this.$refs.defaultSlider?.$slots.default.length;
      if (this.$refs.defaultSlider?.$slots.default.length > 1) {
        clearInterval(this.autoplayInterval);
        this.autoplay(5000);
        this.loadAnim = true;
      }
    },
    autoplay(time) {
      this.autoplayInterval = setInterval(() => {
        if (
          this.$refs &&
          this.$refs.defaultSlider &&
          typeof this.$refs.defaultSlider.next === "function"
        ) {
          this.$refs.defaultSlider.next();
        }
      }, time);
    },
    beforeChange(oldSlideIndex, newSlideIndex) {
      this.loadAnim = false;
      this.currentSlide = newSlideIndex + 1;
    },
    afterChange() {
      clearInterval(this.autoplayInterval);
      this.autoplay(5000);
      setTimeout(() => {
        this.loadAnim = true;
      }, 10);
    },
  },
  mounted() {
     if (this.settings && Object.keys(this.settings).length) {
      Object.assign(this.defaultSettings, this.settings);
    }
  }
};
</script>

<style lang="scss" scoped>
.default-slider {
  &__title {
    margin-bottom: 32px;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 23px;
    line-height: 29px;
    color: #3c4242;

    @media screen and (max-width: 767px) {
      font-size: 21px;
      line-height: 26px;
    }

    &_highlight {
      text-decoration: underline;
      color: #830051;
      transition: 0.3s;
    }
  }

  .material {
    height: 100%;
  }

  &__arrows {
    margin-top: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__arrow {
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #d8dada;
    cursor: pointer;
    color: #8b8e8e;
    transition: 0.3s;

    &:hover {
      color: var(--color-hover);
      background-color: #ebefee;
    }
  }
}
</style>

<style lang="scss">
.default-slider {
  .slick-slider {
    margin: 0 -16px;
  }

  .slick-list,
  .slick-track {
    display: flex;
  }

  .slick-slide {
    padding: 0 16px;
    height: auto;
  }

  .slick-slide > div {
    height: 100%;
  }
}
</style>