<template>
  <div class="modal">
    <div class="modal__content">
      <div class="modal__close" @click="$emit('close')">
        <svg
          width="33"
          height="32"
          viewBox="0 0 33 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7 7L25 25"
            stroke="#3C4242"
            stroke-width="2"
            stroke-linecap="square"
          />
          <path
            d="M25 7L7 25"
            stroke="#3C4242"
            stroke-width="2"
            stroke-linecap="square"
          />
        </svg>
      </div>
      <div class="modal__title">
        {{ title }}
      </div>
      <div class="modal__body">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Modal",
  props: {
    title: String,
  },
};
</script>

<style lang="scss" scoped>
.modal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(31, 31, 31, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  content: "";
  display: block;
  z-index: 1000000;

  &__content {
    min-width: 696px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 48px;
    background-color: #fff;

    @media screen and (max-width: 767px) {
      min-width: 90%;
      padding: 32px 24px 24px;
    }
  }

  &__close {
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;

    @media screen and (max-width: 767px) {
      top: 15px;
      right: 15px;
      width: 24px;
      height: 24px;
    }

    & svg {
      width: 100%;
      height: 100%;
    }
  }

  &__title {
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 38px;
    color: #1f1f1f;
    margin-bottom: 24px;
  }
}
</style>